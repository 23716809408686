import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from '../service/user.service';

@Injectable({ providedIn: 'root' })
export class AuthGuard {

    constructor(public user: UserService, public router: Router) {

    }

    canActivate(): boolean {
        
        if (!this.user.isLoggedIn()) {
            this.router.navigate(['/login']);
            return false;
        }

        return true;
    }

}