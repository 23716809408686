import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { BarcodeFormat } from '@zxing/library';
import { TicketValidationStatus } from '../../entity/TicketValidationStatus';
import { TicketResponse } from '../../entity/TicketResponse';
import { Ticket } from '../../entity/Ticket';
import { ZXingScannerComponent } from '@zxing/ngx-scanner';
import { TicketValidatorService } from '../../service/ticket-validator.service';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-scanner',
  templateUrl: './scanner.component.html',
  styleUrls: ['./scanner.component.scss']
})
export class ScannerComponent implements AfterViewInit {

  @ViewChild('scanner', { static: false })
  scanner!: ZXingScannerComponent;

  allowedFormats = [ BarcodeFormat.QR_CODE, BarcodeFormat.EAN_13, BarcodeFormat.CODE_128, BarcodeFormat.DATA_MATRIX];

  ticketResponse: TicketResponse = { status: TicketValidationStatus.UNKNOWN } as TicketResponse;

  offline: boolean = false;

  private lastReadTime: Date = new Date();

  devices: MediaDeviceInfo[] = [];

  permission!: boolean;

  constructor(private ticketValidatorService: TicketValidatorService) { 
  }

  ngAfterViewInit(): void {
    this.offline = this.ticketValidatorService.isOfflineMode();
    this.scanner.askForPermission();
  }

  public onCamerasFound(devices: MediaDeviceInfo[]): void{
    this.devices = devices;
    this.devices.forEach((device) => console.log("Found devices: "+device.label + " | " + device.kind));
  }

  public scanSuccessHandler(result: string): void {
    if(new Date(this.lastReadTime.getTime() + environment.SCANNER_READ_LOCK_SECONDS) < new Date()){
      this.reset();
      this.lastReadTime = new Date();
      this.measureMetrics();
      this.validateTicket(result);
    }
  }

  public reset(){
     this.ticketResponse = { status: TicketValidationStatus.UNKNOWN } as TicketResponse;
  }

  private validateTicket(ticketToken: string) {

      var ticket = { validity_token: ticketToken } as Ticket;

      this.ticketValidatorService.validateTicket(ticket).subscribe(data => {
        this.ticketResponse = data;
      });
  }


  private measureMetrics(): void {
    localStorage.setItem("scan_counter" , (parseInt(localStorage.getItem("scan_counter") || "0") + 1).toString() );
  }

}
