import { NgModule, ErrorHandler } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { OnlineStatusModule } from 'ngx-online-status';
import * as Sentry from "@sentry/angular";
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { LoginComponent } from './view/login/login.component';
import { ForgottenPasswordComponent } from './view/forgotten-password/forgotten-password.component';
import { RegisterComponent } from './view/register/register.component';
import { ScannerComponent } from './view/scanner/scanner.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { MenuComponent } from './view/menu/menu.component';
import { OptionsComponent } from './view/options/options.component';
import { ZXingScannerModule } from '@zxing/ngx-scanner';
import { ApiInterceptor } from './interceptor/api-interceptor';
import { NavbarComponent } from './view/navbar/navbar.component';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { EventsComponent } from './view/events/events.component';
import { DateAgoPipe } from './pipe/date-ago';
import { CheckTicketComponent } from './view/check-ticket/check-ticket.component';
import { ValidationPopupComponent } from './view/partial/validation-popup/validation-popup.component';
import { AngularDeviceInformationService } from 'angular-device-information';



@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    ForgottenPasswordComponent,
    RegisterComponent,
    ScannerComponent,
    MenuComponent,
    OptionsComponent,
    NavbarComponent,
    EventsComponent,
    CheckTicketComponent,
    DateAgoPipe,
    ValidationPopupComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    OnlineStatusModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    }),
    TranslateModule.forRoot({
      loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [ HttpClient ]
      }
    }),
    ZXingScannerModule
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: ApiInterceptor, multi: true},    
    {provide: ErrorHandler,
    useValue: Sentry.createErrorHandler({
      showDialog: false,
    }),
  },
  AngularDeviceInformationService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}