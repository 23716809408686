import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-forgotten-password',
  templateUrl: './forgotten-password.component.html',
  styleUrls: ['./forgotten-password.component.scss']
})
export class ForgottenPasswordComponent implements OnInit {

  emailForm: FormGroup;
  errorMessage: string = '';
  showForm: boolean = true;
  host!: string;
  logo!: string;

  constructor(
    private fb: FormBuilder,
    private http: HttpClient,
    private translate: TranslateService,
    private router: Router
  ) {
    this.host = environment.HOST;
    this.logo = environment.BRANDING_LOGO;

    this.emailForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]]
    });
  }

  ngOnInit(): void {
  }

  resetPassword() {
    if (this.emailForm.invalid) {
      this.errorMessage = this.translate.instant('forgotten_password.email_invalid');
      return;
    }

    const apiUrl = `${environment.HOST}${environment.API_PATH_PREFIX}/forgotten-password`;
    this.http.post(apiUrl, { email: this.emailForm.value.email })
      .subscribe(
        () => {
          this.errorMessage = this.translate.instant('forgotten_password.reset_link_sent');
          this.showForm = false;
        },
        (error) => {
          console.error('Password reset error:', error);
          this.errorMessage = this.translate.instant('forgotten_password.reset_failed');
        }
      );
  }
}
