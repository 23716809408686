<div class="bg-dark text-white" style="min-height: 100vh; padding-top:58px;">

  <app-navbar *ngIf="!excludeScreenRoutes.includes(currentRoute)"></app-navbar>

  <div *ngIf="networkStatus===0" class="alert alert-danger" style="border-radius: unset;" role="alert">
    <i class="bi bi-wifi-off me-3 fs-5"></i>
    <strong>{{ 'app.network_disconnected' | translate }}</strong>
  </div>

  <div *ngIf="softwareUpdateAvailable" class="alert alert-warning" style="border-radius: unset;" role="alert">
    <div class="row d-flex align-items-center">
      <div class="col-8">
        <strong>{{ 'app.update_available' | translate }}</strong>
      </div>
      <div class="col-4 d-flex justify-content-end">
        <button type="button" (click)="update()" class="btn btn-sm btn-dark">{{ 'app.update' | translate }}</button>
      </div>
    </div>
  </div>

  <div [@routeAnimations]="getRouteAnimationData()">
    <router-outlet></router-outlet>
    <footer class="p-3 fixed-bottom bg-dark" style="max-width: 100vw;">
      <div *ngIf="excludeScreenRoutes.includes(currentRoute)" class="mb-3">
        <a class="col mx-1 text-light" href="#" *ngFor="let language of translate.getLangs()"
          (click)="$event.preventDefault(); switchLanguage(language)">{{language | uppercase}}</a>
      </div>
      <small class="text-white-50">v{{app_version}} | <a class="text-white-50 text-decoration-none" href="https://github.com/SmartNow">SmartNow</a></small>
    </footer>
  </div>
</div>