import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
    name: 'dateAgo',
    pure: true
})
export class DateAgoPipe implements PipeTransform {

    constructor(private translate: TranslateService){

    }

    transform(value: any, args?: Date): any {
        if (value) {
            const seconds = Math.floor((+new Date() - +new Date(value)) / 1000);
            if (seconds < 29) // less than 30 seconds ago will show as 'Just now'
                return this.translate.instant('date_ago.just-now');

            type Interval = { [propKey: string]: number };

            const intervals = {
                'date_ago.year': 31536000,
                'date_ago.month': 2592000,
                'date_ago.week': 604800,
                'date_ago.day': 86400,
                'date_ago.hour': 3600,
                'date_ago.minute': 60,
                'date_ago.second': 1
            } as Interval;
            let counter;
            for (const i in intervals) {
                counter = Math.floor(seconds / intervals[i]);
                if (counter > 0)
                    if (counter === 1) {
                        return counter + ' ' + this.translate.instant(i) + ' ' + this.translate.instant('date_ago.ago'); // singular (1 day ago)
                    } else {
                        return counter + ' ' + this.translate.instant(i) + ' ' + this.translate.instant('date_ago.s ago'); // plural (2 days ago)
                    }
            }
        }
        return value;
    }

}