<div class="container p-5 text-center justify-content-center">

  <div class="card bg-dark rounded shadow border-primary">
    <div class="card-body text-white">
      <i class="bi bi-ticket-perforated fs-1 mb-3"></i>
      <h3>{{ 'menu.check' | translate }}</h3>
    </div>
  </div>

  <div *ngIf="apiCallError !== null" class="alert alert-danger alert-dismissible fade show" role="alert">
    <strong>Error!</strong> {{apiCallError.status}} - {{apiCallError.message}}
    <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
  </div>

  <div *ngIf="mode" class="alert alert-info alert-dismissible fade show mt-5" role="alert">
    <strong>{{ 'ticket_details.offline.alert' | translate }}</strong> {{ 'ticket_details.offline.details' | translate
    }}<br><br><button routerLink="/events" type="button" class="btn btn-warning btn-sm">{{
      'ticket_details.offline.download' | translate }}</button>
    <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
  </div>

  <div>
    <div class="input-group mb-5 my-5">
      <span class="input-group-text" id="basic-addon1">#</span>
      <input type="number" [ngClass]="(ticket === null)? 'form-control is-invalid' : 'form-control'" #ticketId
        placeholder="{{ 'ticket_details.ticket_number' | translate }}" aria-label="Ticket number"
        aria-describedby="basic-addon1">
      <button type="button" (click)="queryTicketDetails(ticketId.value)" class="btn btn-warning">{{
        'ticket_details.details' |
        translate }}</button>
      <span class="invalid-feedback" role="alert">
        <strong>{{ 'ticket_details.not_found' | translate }}</strong>
      </span>
    </div>


  </div>

  <div *ngIf="ticket && ticket.ticket_type" class="mb-5">
    <div class="card my-5" *ngIf="ticket.ticket_type.event">
      <div class="card-header text-dark fw-bold">
        {{ 'ticket_details.event' | translate }}
      </div>
      <ul class="list-group list-group-flush">
        <li class="list-group-item">{{ticket.ticket_type.event.name}}</li>
        <li class="list-group-item">{{ticket.ticket_type.event.date | date: dateFormat }}</li>
        <li class="list-group-item">{{ticket.ticket_type.event.entrance_start | date: dateFormat }} -
          {{ticket.ticket_type.event.entrance_end | date: dateFormat }}</li>
      </ul>
    </div>
    <div class="card mt-3">
      <div class="card-header text-dark fw-bold">
        {{ 'ticket_details.ticket' | translate }}
      </div>
      <ul class="list-group list-group-flush">
        <li class="list-group-item">#{{ticket.id}}</li>
        <li class="list-group-item">{{ticket.ticket_type.name}}</li>
        <li class="list-group-item">{{ticket.created_at | date: dateFormat }}</li>
        <li class="list-group-item">{{ticket.ticket_type.price}} CCY</li>
        <li class="list-group-item">{{ticket.transaction_id}}</li>
        <li class="list-group-item">
          <div class="badge rounded-pill w-25 {{ticketStatus[actualStatus]?.class}} ">{{ticketStatus[actualStatus].name
            | translate }}</div>
        </li>
        <li *ngIf="ticket?.comment !== null" class="list-group-item">{{ticket.comment}}</li>
      </ul>
    </div>
    <div class="card mt-3" *ngIf="ticket.user">
      <div class="card-header text-dark fw-bold">
        {{ 'ticket_details.customer' | translate }}
      </div>
      <ul class="list-group list-group-flush" *ngIf="ticket.user">
        <li class="list-group-item">{{ticket.user.name}}</li>
        <li class="list-group-item">{{ticket.user.email}}</li>
      </ul>
    </div>
    <div *ngIf="ticket?.entered_by !== null" class="card mt-3">
      <div class="card-header text-light fw-bold bg-primary">
        {{ 'ticket_details.entered_by' | translate }}
      </div>
      <ul class="list-group list-group-flush">
        <li class="list-group-item">{{ ticket.entered_by?.name }}</li>
        <li class="list-group-item">{{ticket.entered_at | date: dateFormat }}</li>
      </ul>
    </div>
    <div *ngIf="ticket.status === 2 && ticket.entered_by === null" class="mt-3">
      <button type="button" (click)="validateTicket(ticket)" class="btn btn-primary w-100">{{ 'ticket_details.enter' |
        translate }}</button>
    </div>
  </div>

  <app-validation-popup [reset]="reset" [ticketResponse]="ticketResponse" [mode]="mode"></app-validation-popup>


</div>