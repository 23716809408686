<div class="container px-5 text-center">
    <div class="justify-content-center mt-5">
        <div class="card bg-dark rounded shadow border-primary">
            <div class="card-body">
                <div class="row">
                    <div class="col-6">
                        <img [src]="user.image? host+'/storage/images/users/'+user.image : 'assets/avatar.png'"
                            class="img-thumbnail img-profile rounded-circle" style="width:8rem; height:8rem;"
                            alt="profile">
                    </div>
                    <div class="col-6">
                        <h5 class="mt-3 text-wrap overflow-hidden card-title text-white">{{user.name}}</h5>
                        <h6 class="mt-3 fw-bold text-success" *ngIf="!offlineMode">Online</h6>
                        <h6 class="mt-3 fw-bold text-danger" *ngIf="offlineMode">Offline</h6>
                        <p class="mt-3 text-white-50">Scanned: {{ scan_counter }}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="justify-content-center mt-3" *ngIf="currentEvents.length > 0">
        <div class="card bg-dark rounded shadow border-primary">
            <div class="card-body">
                <div class="row">
                    <div class="col-6">
                        <img src="{{host}}/storage/images/events/{{(currentEvents[0].image? currentEvents[0].image : currentEvents[0].image_secondary )}}"
                            style="height:4.5rem; object-fit:cover;" class="w-100">
                    </div>
                    <div class="col-6 text-white pt-2">
                        <h6>{{ currentEvents[0].name }}</h6>
                        <div class="text-white-50" style="font-size: 10px;">{{ 'menu.entrance_start' | translate }}: {{ currentEvents[0].entrance_start |  date : "HH:mm" }}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <hr [ngClass]="currentEvents.length > 0? 'my-3' : 'my-5'">

    <div>
        <a class="btn btn-success btn-lg w-100 mb-4 fw-bold text-white" routerLink="/scanner"><i
                class="bi bi-qr-code"></i> {{ 'menu.scan' | translate }}</a>
        <a class="btn btn-warning w-100 mb-4 text-white" routerLink="/check"><i class="bi bi-ticket-perforated"></i> {{
            'menu.check' | translate }}</a>
        <a class="btn btn-secondary w-100 mb-4" routerLink="/options"><i class="bi bi-gear"></i> {{ 'menu.options' |
            translate }}</a>
        <a class="btn btn-danger w-100 mb-4 text-white" (click)="logout()"><i class="bi bi-box-arrow-right"></i> {{
            'menu.logout' | translate }}</a>
    </div>
</div>