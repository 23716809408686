import { Component, OnInit } from '@angular/core';
import { User } from '../../entity/User';
import { UserService } from '../../service/user.service';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';
import { TicketValidatorService } from 'src/app/service/ticket-validator.service';
import { EventService } from 'src/app/service/event.service';
import { Event } from '../../entity/Event';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {

  host!: string;
  user!: User;
  scan_counter: number = 0;
  offlineMode!: boolean;
  currentEvents = [] as Event[];

  constructor(private router: Router, private userService: UserService, private ticketValidatorService: TicketValidatorService, private eventService: EventService) { 
    this.host = environment.HOST;
  }

  ngOnInit(): void {
    this.scan_counter = parseInt(localStorage.getItem("scan_counter") || "0");
    this.user = this.userService.getUser();
    this.offlineMode = this.ticketValidatorService.isOfflineMode();
    
    this.eventService.getCurrentEvents().subscribe(events => this.currentEvents = events);

  }

  logout(){
    this.userService.logout();
    this.router.navigate(['/login']);
  }

}
