<div class="fixed-bottom p-0 m-0" style="z-index: 9999;" (click)="reset()" *ngIf="ticketResponse.status !== TICKET_VALIDATION_ENUM.UNKNOWN && ticketResponse.ticket"> 
    <div class="card text-white" [class.bg-success]="ticketResponse.status === TICKET_VALIDATION_ENUM.SUCCESS"
                                 [class.bg-warning]="ticketResponse.status === TICKET_VALIDATION_ENUM.WARNING" 
                                 [class.bg-danger]="ticketResponse.status === TICKET_VALIDATION_ENUM.ERROR">
      <div class="card-header">

        <div class="row">
          <div class="col-8 text-start" *ngIf="ticketResponse.status === TICKET_VALIDATION_ENUM.SUCCESS">{{ 'scanner.entrance.approved' | translate }}</div>
          <div class="col-8 text-start" *ngIf="ticketResponse.status === TICKET_VALIDATION_ENUM.WARNING">{{ 'scanner.entrance.problem' | translate }}</div>
          <div class="col-8 text-start" *ngIf="ticketResponse.status === TICKET_VALIDATION_ENUM.ERROR">{{ 'scanner.entrance.forbidden' | translate }}</div>

          <div class="col-4 text-end text-light" style="font-size: 12px;">{{ mode? 'Offline' : 'Online' }}</div>
        </div>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col-4 text-center">
              <i class="bi" 
              [class.bi-check-circle-fill]="ticketResponse.status === TICKET_VALIDATION_ENUM.SUCCESS"  
              [class.bi-exclamation-triangle-fill]="ticketResponse.status === TICKET_VALIDATION_ENUM.WARNING"  
              [class.bi-x-octagon-fill]="ticketResponse.status === TICKET_VALIDATION_ENUM.ERROR"  
              style="font-size: 5rem;"></i>
          </div>
          <div class="col-8 text-center pt-4">
            <h4>#{{ticketResponse.ticket.id}} - {{ticketResponse.ticket.ticket_type.name || ('scanner.unknown' | translate) }}</h4>
            <h5 class="card-title">{{ticketResponse.message}}</h5>
          </div>
        </div>
      </div>
    </div>
  </div>