<div class="containertext-center p-5 text-center justify-content-center">
  <div class="card bg-dark rounded shadow border-primary">
    <div class="card-body text-white">
  <i class="bi bi-calendar-event fs-1 mb-3"></i>
  <h3>{{ 'events.events' | translate }}</h3>
  </div>
  </div>
</div>
<div class="container p-5 pt-0 text-center justify-content-center">

  <div *ngIf="events && events.length > 0">
    <div class="row text-center justify-content-center mb-5" *ngFor="let event of events">
        <div class="card mb-3 bg-dark shadow text-white" style="height: 10rem; overflow:hidden;">
            <div class="row g-0">
              <div class="col-4">
                <img src="{{host}}/storage/images/events/{{(event.image_secondary? event.image_secondary : event.image )}}" class="img-fluid" style="height:10rem; object-fit: cover;" class="w-100">
              </div>
              <div class="col-8">
                <div class="card-body rounded text-start">
                  <h6 class="card-title">{{(event.name.length>42)? (event.name | slice:0:42)+'...':(event.name)}}</h6>
                  <p class="card-text">
                    <small class="text-white-50">{{event.date | date}}</small> | <small class="text-white-50">{{event.type}}</small><br>
                  </p>  
                  <div class="text-center">
                    <button class="btn border-5 btn-warning w-100 btn-sm" *ngIf="!event.synchronizedAt" (click)="downloadEvent(event.id)"><i class="bi bi-download fw-bold"></i> {{ 'events.download' | translate }}</button>
                    <button class="btn border-5 btn-success w-100 text-light btn-sm" *ngIf="event.synchronizedAt" (click)="synchronizeEvent(event.id)">
                      <div class="row">
                        <div class="bi bi-arrow-repeat fw-bold col-3" [class.bi-rotate]="syncInprogress" ></div>
                        <div class="col-9 text-start">{{event.synchronizedAt | dateAgo}} </div>
                      </div>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
    </div>
  </div>
  <div *ngIf="!events || events.length === 0">{{ 'events.no_data' | translate }}</div>

</div>