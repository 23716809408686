import { Component, Input } from '@angular/core';
import { TicketValidationStatus } from '../../../entity/TicketValidationStatus';
import { TicketResponse } from '../../../entity/TicketResponse';

@Component({
  selector: 'app-validation-popup',
  templateUrl: './validation-popup.component.html',
  styleUrls: ['./validation-popup.component.scss']
})
export class ValidationPopupComponent {

  public TICKET_VALIDATION_ENUM = TicketValidationStatus;

  @Input()
  public ticketResponse: TicketResponse = { status: TicketValidationStatus.UNKNOWN } as TicketResponse;

  @Input()
  public mode: boolean = false;

  @Input()
  public reset!: () => void;

}
