<nav class="navbar navbar-dark bg-dark fixed-top border-bottom border-primary" style="max-width: 100vw;">
    <div class="container px-4">
        <a class="navbar-brand"><i class="bi bi-ticket-perforated-fill me-2"></i> Ticket Scanner</a>
        <div routerLink="/menu" class="">
            <span class="navbar-toggler-icon"></span>
        </div>
    </div>
</nav>
<div *ngIf="broadcast_message !== null" class="alert alert-warning alert-dismissable" style="border-radius: unset;" role="alert">
    <strong>{{broadcast_message}}</strong>
</div> 