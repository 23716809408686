<div class="container text-center p-5">
    <div class="m-auto mt-3">
        <img [src]="host + '/' + logo" style="width:5rem;" /><br>
        <div class="mb-5 mt-3">
            <h1 class="display">Ticket Scanner</h1>
            <small class="text-white-50">by SmartNow</small>
        </div>
        <form (ngSubmit)="login()">
            <div class="mb-3">
                <label for="username" class="form-label">{{ 'login.username' | translate }}</label>
                <input type="text" [ngClass]="(errorMessage !== undefined)? 'form-control is-invalid' : 'form-control'"
                    id="username" aria-describedby="usernameHelp" name="username" [(ngModel)]="form.username" required>
            </div>
            <div class="mb-3">
                <label for="exampleInputPassword1" class="form-label">{{'login.password' | translate}}</label>
                <input type="password"
                    [ngClass]="(errorMessage !== undefined)? 'form-control is-invalid' : 'form-control'"
                    id="exampleInputPassword" name="password" [(ngModel)]="form.password" required>
                <span class="invalid-feedback" role="alert">
                    <strong>{{errorMessage}}</strong>
                </span>
            </div>
            <br>
            <button type="submit" class="btn btn-primary w-100 mt-3 px-5 fw-bold">{{ 'login.login' | translate
                }}</button>
        </form>
        <div class="mt-4">
            <p>
                <a routerLink="/register"
                    class="register-link text-white-50 link link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover">
                    {{ 'login.register' | translate }}
                </a>
                <span class="text-white-50 mx-1">|</span>
                <a routerLink="/forgotten-password"
                    class="forgot-password-link text-white-50 link link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover">
                    {{ 'login.forgotten_password' | translate }}
                </a>
            </p>
        </div>
    </div>
</div>