import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpErrorResponse,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpParams
} from '@angular/common/http';
import { Observable, catchError, of, throwError, timeout } from 'rxjs';
import { UserService } from '../service/user.service';
import { User } from '../entity/User';
import { Router } from '@angular/router';

const AUTH_API_ENDPOINT = "auth";
const REGISTER_API_ENDPOINT = "register";

@Injectable()
export class ApiInterceptor implements HttpInterceptor {

  constructor(private router: Router, private userService: UserService) {}

  intercept(r: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    const request = r.clone({
      headers: r.headers.set('Content-Type', 'application/json')
                        .set('Accept', 'application/json') 
                       // .set('ngsw-bypass', 'true')
    });

    var closure = next.handle(request);

    if(request.url.includes(AUTH_API_ENDPOINT) || request.url.includes(REGISTER_API_ENDPOINT)){
      return closure.pipe(timeout(10000));
    }

    const user: User = this.userService.getUser();

    if(user){
      const requestWithToken = request.clone({
        params: (request.params ? request.params : new HttpParams()).set('api_token', user.api_token)
      });

      closure = next.handle(requestWithToken);
    } 

    return closure.pipe(timeout(10000), catchError(x => this.handleAuthError(x)));
  }


  private handleAuthError(err: HttpErrorResponse): Observable<any> {

    if (err.status === 401 || err.status === 403) {

        this.userService.logout();

        this.router.navigateByUrl(`/login`);
      
        return of(err.message);
    }
      return throwError(err);
  }

}
