import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { UserService } from '../../service/user.service';
import { environment } from '../../../environments/environment';
import { catchError, tap } from 'rxjs/operators';
import { of } from 'rxjs';
import { User } from 'src/app/entity/User';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent {

  host!: string;
  logo!: string;
  errorMessage!: string;

  form: any = {
    username: null,
    password: null
  };

  constructor(private router: Router, private translate: TranslateService, private userService: UserService) {
    this.host = environment.HOST;
    this.logo = environment.BRANDING_LOGO;
  }

  public login() {
    const { username, password } = this.form;

    if (!username || !password) {
      this.errorMessage = this.translate.instant("login.username_password_wrong");
      return;
    }

    this.userService.login({ username: username, password: password } as User)
      .pipe(
        catchError(error => {
          console.error('Login error:', error);
          switch (error.status) {
            case 401:
              this.errorMessage = this.translate.instant("login.username_password_wrong");
              break;
            default:
              this.errorMessage = this.translate.instant("login.login_failed");
              break;
          }
          return of(error);
        }),
        tap(user => {
          if (user) {
            this.router.navigate(['/menu']);
          } else {
            this.errorMessage = this.translate.instant("login.permission_denied");
          }
        })
      ).subscribe();
  }
}
