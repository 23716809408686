<div class="">

    <div class="accordion accordion-flush fixed-top" style="margin-top: 56px;" id="accordionFlushExample">
        <div class="accordion-item border border-primary" >
          <h2 class="accordion-header" id="flush-headingOne">
            <button class="accordion-button collapsed bg-dark text-white px-4" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
              {{ 'scanner.camera' | translate }}
            </button>
          </h2>
        </div>
        <div id="flush-collapseOne" class="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
            <div class="accordion-body bg-dark text-white container">
                <div *ngFor="let camera of devices; index as i" style="font-size: 12px;">
                    <div class="row">
                        <div class="col-6">#{{i+1}} - {{ camera.label }}</div>
                    </div>
                </div>
            </div>
          </div>
    </div>

    <zxing-scanner #scanner
        [enable]="true"
        [formats]="allowedFormats"
        previewFitMode="cover"
        (scanSuccess)="scanSuccessHandler($event)"
        (camerasFound)="onCamerasFound($event)"
    ></zxing-scanner>

    <app-validation-popup [reset]="reset" [ticketResponse]="ticketResponse" [mode]="offline"></app-validation-popup>
 
</div>
