import { Component, OnInit } from '@angular/core';
import { environment } from '../../../environments/environment';
import { IConstants } from '../../IConstants';
import { TranslateService } from '@ngx-translate/core';
import { TicketValidatorService } from 'src/app/service/ticket-validator.service';
import { AngularDeviceInformationService, DeviceInfo } from 'angular-device-information';
import { AutoSyncService } from 'src/app/service/auto-sync.service';

@Component({
  selector: 'app-options',
  templateUrl: './options.component.html',
  styleUrls: ['./options.component.scss']
})
export class OptionsComponent implements OnInit {

  host!: string;
  offlineMode: boolean = false;
  autoSync: boolean = false;
  currentLang!: string;
  deviceInfo!: DeviceInfo;
  environment!: any;

  constructor(private deviceInformationService: AngularDeviceInformationService, private ticketValidatorService: TicketValidatorService, private autoSyncServie: AutoSyncService, private translate: TranslateService) { 
  }

  ngOnInit(): void {
    this.environment = environment;
    this.offlineMode = this.ticketValidatorService.isOfflineMode();
    this.autoSync = this.autoSyncServie.isAutoSyncActive();
    this.currentLang = this.translate.currentLang;
    this.deviceInfo = this.deviceInformationService.getDeviceInfo();
  }

  public clearCache(): void {
    localStorage.removeItem("scan_counter");
   // this.db.deleteDatabase();
  }

  public toggleOfflineMode(){
    this.ticketValidatorService.toggleMode();
    this.offlineMode = this.ticketValidatorService.isOfflineMode();
  }

  public toggleAutoSync(){
    this.autoSyncServie.toggleAutoSync();
    this.autoSync = this.autoSyncServie.isAutoSyncActive();
  }

  public switchLanguage(event: Event) {
    const lang = (event.target as HTMLInputElement).value;

    this.translate.use(lang.toLowerCase());
    localStorage.setItem(IConstants.CONSTANT_LANGUAGE_STORAGE_NAME, lang.toLowerCase());
  }

}
