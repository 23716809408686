<div class="containertext-center p-5 text-center justify-content-center">
  <div class="card bg-dark rounded shadow border-primary">
    <div class="card-body text-white">
    <i class="bi bi-gear fs-1 mb-3"></i>
    <h3 class="fw-bold">{{ 'options.options' | translate }}</h3>
    </div>
    </div>
</div>
    <div class="accordion accordion-flush mb-5" id="accordionFlushExample">
        <div class="accordion-item border border-primary" >
          <h2 class="accordion-header" id="flush-headingOne">
            <button class="accordion-button collapsed bg-dark text-white" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
              {{ 'options.generic' | translate }}
            </button>
          </h2>
          <div id="flush-collapseOne" class="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
            <div class="accordion-body bg-dark text-white">
                <div class="input-group m-auto p-3">
                    <span class="input-group-text fw-bold" id="basic-addon1">{{ 'options.server' | translate }}</span>
                    <input type="text" class="form-control" id="server" aria-describedby="server" name="server" [(ngModel)]="environment.HOST" readonly> 
                </div>
                <hr>
                <div class="input-group m-auto p-3">
                  <span class="input-group-text fw-bold" id="basic-addon1">{{ 'options.platform' | translate }}</span>
                  <input type="text" class="form-control" id="platform" aria-describedby="platform" name="platform" [value]="deviceInfo.os + ' - ' + deviceInfo.osVersion"  readonly> 
              </div>

              <div class="input-group m-auto p-3">
                <span class="input-group-text fw-bold" id="basic-addon1">{{ 'options.client' | translate }}</span>
                <input type="text" class="form-control" id="client" aria-describedby="client" name="client" [value]="deviceInfo.userAgent"  readonly> 
            </div>
            </div>
          </div>
        </div>
        <div class="accordion-item border border-primary" >
          <h2 class="accordion-header" id="flush-headingTwo">
            <button class="accordion-button collapsed bg-dark text-white" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
              {{ 'options.offline_mode' | translate }}
            </button>
          </h2>
          <div id="flush-collapseTwo" class="accordion-collapse collapse" aria-labelledby="flush-headingTwo" data-bs-parent="#accordionFlushExample">
            <div class="accordion-body bg-dark text-white">

              <div class="card-body p-3">
                
                <div class="row">
                
                <div class="col-6">
                  <div class="form-check form-switch">
                    <label class="form-check-label" for="flexSwitchCheckDefault">{{ 'options.offline_mode' | translate }}</label>
                    <input class="form-check-input input-lg" (change)="toggleOfflineMode()" type="checkbox" role="switch" id="flexSwitchCheckDefault" [checked]="offlineMode">
                  </div>  
                </div>
                
                <div class="col-6">
                  <button class="btn btn-warning btn-sm w-100" routerLink="/events" [ngClass]="offlineMode? '' : 'disabled'"><i class="bi bi-download"></i> {{ 'options.download_data' | translate }}</button>
                </div>
                </div>
              </div>



              <div class="row">
                
                <div class="col-10 ps-4">
                  {{ 'options.auto_sync' | translate }} ({{ environment.AUTO_SYNC_INTERVAL/60000 }} min)
                </div>

                <div class="col-2">
                  <div class="form-check form-switch form-reverse">
                    <input class="form-check-input input-lg" (change)="toggleAutoSync()" [attr.disabled]="offlineMode? null : 'disabled'" type="checkbox" role="switch" id="flexSwitchCheckDefault" [checked]="autoSync">
                  </div>  
                </div>
                
              </div>
                
            </div>
          </div>
        </div>
        <div class="accordion-item border border-primary">
          <h2 class="accordion-header" id="flush-headingThree">
            <button class="accordion-button collapsed bg-dark text-white" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
              {{ 'options.language' | translate }}
            </button>
          </h2>
          <div id="flush-collapseThree" class="accordion-collapse collapse" aria-labelledby="flush-headingThree" data-bs-parent="#accordionFlushExample">
            <div class="accordion-body bg-dark text-white">
              <select class="form-select" (change)="switchLanguage($event)">
                <option *ngFor="let l of ['EN', 'HU']" [value]="l" [selected]="l.toLowerCase()===currentLang.toLocaleLowerCase()">{{l}}</option>
              </select>
            </div>
          </div>
        </div>
        <div class="accordion-item border border-primary">
          <h2 class="accordion-header" id="flush-headingFour">
            <button class="accordion-button collapsed bg-dark text-white" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFour" aria-expanded="false" aria-controls="flush-collapseFour">
              {{ 'options.local_storage' | translate }}
            </button>
          </h2>
          <div id="flush-collapseFour" class="accordion-collapse collapse" aria-labelledby="flush-headingFour" data-bs-parent="#accordionFlushExample">
            <div class="accordion-body bg-dark text-white">
                <button class="btn btn-warning btn-sm w-100" (click)="clearCache()">{{ 'options.clear_cache' | translate }}</button>
            </div>
          </div>
        </div>
      </div>