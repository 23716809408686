import { Component, OnInit } from '@angular/core';
import { Event } from '../../entity/Event';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { OfflineDatabase } from '../../repository/OfflineDatabase';
import { delay } from 'rxjs';
import { EventService } from 'src/app/service/event.service';

@Component({
  selector: 'app-events',
  templateUrl: './events.component.html',
  styleUrls: ['./events.component.scss']
})
export class EventsComponent implements OnInit {

  host!: string;

  events: Event[] = [];

  syncInprogress: boolean = false;

  constructor(private db: OfflineDatabase, private http: HttpClient, private eventService: EventService) {
  }

  ngOnInit(): void {
    this.host = environment.HOST;

    this.eventService.getEvents()
      .subscribe(r => {
        this.events = r;
        this.checkDownloaded();
      });

  }

  private checkDownloaded() {
    this.events.forEach(event => {
      event.synchronizedAt = undefined;
      this.db.events.get(event.id)
        .then(e => { event.synchronizedAt = (e !== undefined ? e.synchronizedAt : undefined) })
        .catch(e => event.synchronizedAt = undefined);
    });
  }

  public downloadEvent(eventId: number){
    this.startAnimation()
    this.eventService.downloadEvent(eventId).subscribe(r => {
      this.checkDownloaded();
      this.stopAnimation();
    });
  }

  public synchronizeEvent(eventId: number) {

    this.startAnimation();

    this.eventService.synchronizeEvent(eventId).pipe(delay(1500)).subscribe(d => {
      this.checkDownloaded();
      this.stopAnimation();
    });

  }

  private startAnimation() {
    this.syncInprogress = true;
  }

  private stopAnimation() {
    new Promise(f => setTimeout(f, 2500));
    this.syncInprogress = false;
  }

}
