import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, of, map, tap, from, mergeMap, retry } from 'rxjs';
import { environment } from 'src/environments/environment';
import { UserService } from './user.service';
import { Event } from '../entity/Event';
import { Ticket } from '../entity/Ticket';
import { OfflineDatabase } from '../repository/OfflineDatabase';

@Injectable({
  providedIn: 'root'
})
export class EventService {

  constructor(private http: HttpClient, private userService: UserService, private db: OfflineDatabase) {

  }

  public getEvents() {
    return this.http.get<Event[]>(environment.HOST + environment.API_PATH_PREFIX + 'guards/' + this.userService.getUser().id + '/events', {
      observe: 'response'
    }).pipe(
      map(d => {
        const yesterday: Date = new Date();
        yesterday.setDate(yesterday.getDate() - 1);

        return (d.body as Event[]).filter(e => e.active == 1 && (new Date(e.date) > yesterday)).reverse();
      }),
      catchError(e => {
        console.log(e);
        return of(e);
      })
    );
  }

  public getCurrentEvents() {

    return this.getEvents().pipe(map(events => {

      if(events){
        const today = (new Date()).toISOString().slice(0, 10);
        events = events.filter((e: Event) => (new Date(e.date)).toISOString().slice(0, 10) === today);
      }

      return events;
    }));
  }

  public downloadEvent(eventId: number) {

    return this.http.get<Event>(environment.HOST + environment.API_PATH_PREFIX + 'events/' + eventId, {
      observe: 'response'
    }).pipe(
      tap(d => {

        var downloadedEvent = d.body as Event;
        downloadedEvent.synchronizedAt = new Date();

        this.db.events.add(downloadedEvent).then(e => {

          this.http.get<Ticket[]>(environment.HOST + environment.API_PATH_PREFIX + 'events/' + eventId + '/tickets', {
            observe: 'response'
          })
            .subscribe((response: any) => {

              // console.log(response.body as Ticket[]);

              this.db.tickets.bulkAdd(response.body as Ticket[]);

            });
        });

      }),
      catchError(e => {
        console.log(e);
        this.db.events.delete(eventId);
        return of(e);
      })
    );

  }

  public synchronizeEvent(eventId: number) {

    return from(this.db.events.get(eventId)).pipe(mergeMap(bevent => {

      if (bevent?.synchronizedAt !== undefined) {
        const syncAt: Date = bevent?.synchronizedAt;

        return from(this.db.tickets.where({ laravel_through_key: "" + eventId }).toArray()).
          pipe(
            map(tickets => {
              return tickets.filter((ticket) => ticket.entered_at !== undefined && (ticket.entered_at > syncAt));
            }),
            mergeMap(toBeSynchronized =>
              this.http.put(environment.HOST + environment.API_PATH_PREFIX + 'tickets/synchronize', toBeSynchronized)
                .pipe(
                  retry(2),
                  mergeMap(r => {
                    return from(this.db.events.delete(eventId)).pipe(mergeMap(e => {
                      return from(this.db.tickets.where({ laravel_through_key: "" + eventId }).delete()).pipe(mergeMap(d => {
                        return this.downloadEvent(eventId);
                      }));
                    }));
                  }),
                  catchError(error => {
                    console.log(error);
                    return of(error);
                  })
                )
            ));

      }

      return of();
    }));
  }

  public getDownloadedEvents() {
    return from(this.db.events.toArray());
  }

}
