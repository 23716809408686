import { Component, OnInit } from '@angular/core';
import { Ticket } from '../../entity/Ticket';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { TicketValidatorService } from '../../service/ticket-validator.service';
import { TicketResponse } from '../../entity/TicketResponse';
import { TicketValidationStatus } from '../../entity/TicketValidationStatus';
import { OfflineDatabase } from '../../repository/OfflineDatabase';
import { tap, catchError, from, of, mergeMap, map } from 'rxjs';


@Component({
  selector: 'app-check-ticket',
  templateUrl: './check-ticket.component.html',
  styleUrls: ['./check-ticket.component.scss']
})
export class CheckTicketComponent implements OnInit {

  ticket!: Ticket | null | undefined;

  ticketStatus: any[] = [
                         { name: 'ticket_details.status.error', class: "bg-danger" }, 
                         { name: 'ticket_details.status.pending', class: "bg-warning" }, 
                         { name: 'ticket_details.status.purchased', class: "bg-success" }, 
                         { name: 'ticket_details.status.entered', class: "bg-primary" }
                        ];

  actualStatus!: number;

  apiCallError: HttpErrorResponse | null = null;

  ticketResponse: TicketResponse = { status: TicketValidationStatus.UNKNOWN } as TicketResponse;

  TICKET_VALIDATION_ENUM = TicketValidationStatus;

  dateFormat = 'yyyy-MM-dd HH:mm:ss';

  public mode!: boolean;

  constructor(private http: HttpClient, private db: OfflineDatabase, private ticketValidatorService: TicketValidatorService) { }

  ngOnInit(): void {
    this.mode = this.ticketValidatorService.isOfflineMode();
  }

  public queryTicketDetails(ticketId: string) {

    if (this.mode) {
      this.checkOffline(Number(ticketId));
    } else {
      this.checkOnline(Number(ticketId));
    }

  }

  private checkOffline(ticketId: number) {

    from(this.db.tickets.where({ id: ticketId }).first()).pipe(
      catchError(e => { this.apiCallError = e; return of(e) }),
      mergeMap(ticket => {
        return from(this.db.events.get(Number(ticket.ticket_type.event_id))).pipe(map(
          event => {
            
            console.log(event);

            if (ticket) {
              ticket.ticket_type.event = event;
            }

            return ticket;
          }
        ))
      })
    ).subscribe(ticket => {
        this.ticket = ticket || null;
        this.actualStatus = this.ticket?.entered_at == null ? (this.ticket?.status || 0) : 3;

        this.apiCallError = null;
      });

  }

  private checkOnline(ticketId: number) {

    this.http.get<Ticket>(environment.HOST + environment.API_PATH_PREFIX + 'ticket/' + ticketId, {
      observe: 'response'
    })
      .pipe(
        tap(d => {
          this.ticket = d.body;
          this.actualStatus = this.ticket?.entered_at == null ? (this.ticket?.status || 0) : 3;

          this.apiCallError = null;
        }),
        catchError(error => this.apiCallError = error))
      .subscribe();

  }

  public validateTicket(ticket: Ticket) {
    this.ticketValidatorService.validateTicket(ticket).subscribe((data: TicketResponse) => {
      this.ticket = data.ticket;
      this.ticketResponse = data;
    });
  }

  public reset() {
    this.ticketResponse = { status: TicketValidationStatus.UNKNOWN } as TicketResponse;
  }

}
