<div class="container text-center p-5">
    <div class="m-auto mt-3">
        <img [src]="host + '/' + logo" style="width:5rem;" /><br>
        <div class="mb-5 mt-3">
            <h1 class="display">Ticket Scanner</h1>
            <small class="text-white-50">by SmartNow</small>
        </div>
        <form (ngSubmit)="resetPassword()" [formGroup]="emailForm" *ngIf="showForm">
            <div class="mb-3">
                <label for="email" class="form-label">{{ 'forgotten_password.email' | translate }}</label>
                <input type="email" class="form-control" id="email" formControlName="email" required>
                <div *ngIf="emailForm.get('email')?.invalid && (emailForm.get('email')?.dirty || emailForm.get('email')?.touched)"
                    class="text-danger">
                    <div *ngIf="emailForm.get('email')?.errors?.['required']">
                        {{ 'register.email_required' | translate }}
                    </div>
                    <div *ngIf="emailForm.get('email')?.errors?.['email']">
                        {{ 'register.invalid_email' | translate }}
                    </div>
                </div>
                <p *ngIf="errorMessage" class="mt-3 text-danger">{{ errorMessage }}</p>
            </div>
            <div class="mb-3">
                <button type="submit" class="btn btn-primary w-100 mt-3 px-5 fw-bold">
                    {{ 'forgotten_password.reset_password' | translate }}
                </button>
            </div>
        </form>
            <div *ngIf="!showForm">
                <div class="alert alert-success" style="border-radius: unset;" role="alert">
                    <i class="bi bi-check-circle-fill me-3 fs-5"></i>
                    <strong>{{ errorMessage }}</strong>
                </div>
            </div>
            <p class="mt-3">
                <a routerLink="/login"
                    class="text-white-50 link link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover">
                    {{ 'forgotten_password.back_to_login' | translate }}
                </a>
            </p>
    </div>
</div>