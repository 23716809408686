import Dexie from "dexie";
import { Injectable } from "@angular/core";
import { Event } from "../entity/Event";
import { Ticket } from "../entity/Ticket";

const DATABASE_NAME: string = "offline-db";

@Injectable({
    providedIn: 'root'
  })
export class OfflineDatabase extends Dexie {

    public events: Dexie.Table<Event,number>
    public tickets: Dexie.Table<Ticket,string>

    public constructor() {
        super(DATABASE_NAME);
        this.version(1.1).stores({
            events: "++id",
            tickets: "++validity_token, id, laravel_through_key, ticket_type.event_id",
        });

        this.events = this.table("events");
        this.tickets = this.table("tickets");

        this.on('populate', () => this.migrate());

        // this.tickets.mapToClass(Ticket);
    }

    async migrate(){
        await this.events.bulkAdd([] as Event[]);
        await this.tickets.bulkAdd([] as Ticket[]);
    }

    public deleteDatabase(){
        var req = indexedDB.deleteDatabase(DATABASE_NAME);
        req.onsuccess = function () {
            console.log("Deleted database successfully");
        };
        req.onerror = function () {
            console.log("Couldn't delete database");
        };
        req.onblocked = function () {
            console.log("Couldn't delete database due to the operation being blocked");
        };
    }

}