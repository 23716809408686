import { Injectable, OnInit } from '@angular/core';
import { IConstants } from '../IConstants';
import { EventService } from './event.service';
import { Subscription, interval, mergeMap } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AutoSyncService implements OnInit {

  autoSyncInterval!: Subscription;

  constructor(private eventService: EventService) { }

  ngOnInit(): void {
    if(this.isAutoSyncActive()){
      this.scheduleAutoSync();
    }
  }

  public toggleAutoSync(){
    this.setAutoSync(this.isAutoSyncActive()? false : true);

    if(this.isAutoSyncActive()){
      this.scheduleAutoSync();
    }

    if(!this.isAutoSyncActive()){
      this.stopAutoSync();
    }

  }

  public isAutoSyncActive(): boolean {
    return JSON.parse(localStorage.getItem(IConstants.CONSTANT_AUTO_SYNC_STORAGE_NAME) || 'false' );
  }

  public setAutoSync(mode: boolean): void {
    localStorage.setItem(IConstants.CONSTANT_AUTO_SYNC_STORAGE_NAME, JSON.stringify(mode));
  }

  public scheduleAutoSync(){

    console.log("Auto sync enabled");

    this.autoSyncInterval = interval(environment.AUTO_SYNC_INTERVAL).pipe(mergeMap(() => this.eventService.getDownloadedEvents())).subscribe((events) => {

      console.log((new Date()) + " Auto sync "+events.length+" events...");

      events.forEach(event => {
        this.eventService.synchronizeEvent(event.id).subscribe();
      });
    });
    
  }

  public stopAutoSync(){
    if(this.autoSyncInterval){
      console.log("Auto sync disabled");
      this.autoSyncInterval.unsubscribe();
    }
  }

}
