import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { TicketValidationStatus } from '../entity/TicketValidationStatus';
import { TicketResponse } from '../entity/TicketResponse';
import { environment } from '../../environments/environment';
import { Observable, tap, of, catchError, from, map, retry } from 'rxjs';
import { Ticket } from '../entity/Ticket';
import { IConstants } from '../IConstants';
import { OfflineDatabase } from '../repository/OfflineDatabase';
import { TranslateService } from '@ngx-translate/core';
import { UserService } from './user.service';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
};

@Injectable({
  providedIn: 'root'
})
export class TicketValidatorService {

  constructor(private db: OfflineDatabase, private http: HttpClient, private translate: TranslateService, private userService: UserService) { }

  public validateTicket(ticket: Ticket): Observable<TicketResponse> {
      return this.isOfflineMode()? this.validateTicketOffline(ticket.validity_token) : this.validateTicketOnline(ticket.validity_token);
  }

  public toggleMode(){
    this.setMode(this.isOfflineMode()? false : true);
  }

  public isOfflineMode(): boolean {
    return JSON.parse(localStorage.getItem(IConstants.CONSTANT_OFFLINE_MODE_STORAGE_NAME) || 'false' );
  }

  public setMode(mode: boolean): void {
    localStorage.setItem(IConstants.CONSTANT_OFFLINE_MODE_STORAGE_NAME, JSON.stringify(mode));
  }

  private validateTicketOffline(validityToken: string): Observable<TicketResponse> {

    const ticketResponse = {} as TicketResponse;
    
    return from(this.db.tickets.get(validityToken)).pipe(
      catchError(error => {
        ticketResponse.status = TicketValidationStatus.ERROR; 
        ticketResponse.message = error.message;

        return of();
      }),
      map(t => {

        if(t===undefined){
          
          ticketResponse.status = TicketValidationStatus.ERROR;
          ticketResponse.message = this.translate.instant("ticket_details.message.used");

          return ticketResponse;
        }

        ticketResponse.ticket = t;
  
        if(ticketResponse.ticket.entered_at){
          ticketResponse.status = TicketValidationStatus.ERROR;
          ticketResponse.message = this.translate.instant("ticket_details.message.used");
        } else if (ticketResponse.ticket.status != 2){
          ticketResponse.status = TicketValidationStatus.ERROR;
          ticketResponse.message = this.translate.instant("ticket_details.message.unpaid");
        } else if(
          (ticketResponse.ticket.ticket_type.valid_from && ticketResponse.ticket.ticket_type.valid_from <= new Date()) || 
          (ticketResponse.ticket.ticket_type.valid_to && ticketResponse.ticket.ticket_type.valid_to >= new Date())
        ){
          ticketResponse.status = TicketValidationStatus.ERROR;
          ticketResponse.message = this.translate.instant("ticket_details.message.not_valid_period");
        } else {
          ticketResponse.ticket.entered_at = new Date();
          ticketResponse.ticket.entered_by = this.userService.getUser();
  
          this.db.tickets.update(validityToken, {     
            entered_at: ticketResponse.ticket.entered_at,
            entered_by: ticketResponse.ticket.entered_by
          });
  
          ticketResponse.status = TicketValidationStatus.SUCCESS;
          ticketResponse.message = this.translate.instant("ticket_details.message.success");
        }
  
        return ticketResponse;
      })
    );

  }

  private validateTicketOnline(validityToken: string): Observable<TicketResponse> {

    return this.http.post<TicketResponse>(environment.HOST+environment.API_PATH_PREFIX+'validate-ticket/'+validityToken, {
      observe: 'response'
    }, httpOptions).pipe(
      retry(2),
      tap((response: TicketResponse) => {
        if(response){
          response.status = TicketValidationStatus.SUCCESS;
        }
        return response;
      }),
      catchError((error) => {
        error.error.status = [400, 423].includes(error.status)? TicketValidationStatus.WARNING : TicketValidationStatus.ERROR;
        return of(error.error);
      })
    );

  }

}
